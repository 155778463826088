exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aviation-tsx": () => import("./../../../src/pages/aviation.tsx" /* webpackChunkName: "component---src-pages-aviation-tsx" */),
  "component---src-pages-construction-tsx": () => import("./../../../src/pages/construction.tsx" /* webpackChunkName: "component---src-pages-construction-tsx" */),
  "component---src-pages-features-didit-mobile-apps-tsx": () => import("./../../../src/pages/features/didit-mobile-apps.tsx" /* webpackChunkName: "component---src-pages-features-didit-mobile-apps-tsx" */),
  "component---src-pages-features-public-access-for-remote-workers-tsx": () => import("./../../../src/pages/features/public-access-for-remote-workers.tsx" /* webpackChunkName: "component---src-pages-features-public-access-for-remote-workers-tsx" */),
  "component---src-pages-features-recurring-checklists-tsx": () => import("./../../../src/pages/features/recurring-checklists.tsx" /* webpackChunkName: "component---src-pages-features-recurring-checklists-tsx" */),
  "component---src-pages-finance-tsx": () => import("./../../../src/pages/finance.tsx" /* webpackChunkName: "component---src-pages-finance-tsx" */),
  "component---src-pages-health-tsx": () => import("./../../../src/pages/health.tsx" /* webpackChunkName: "component---src-pages-health-tsx" */),
  "component---src-pages-hotels-hospitality-tsx": () => import("./../../../src/pages/hotels-hospitality.tsx" /* webpackChunkName: "component---src-pages-hotels-hospitality-tsx" */),
  "component---src-pages-imprint-tsx": () => import("./../../../src/pages/imprint.tsx" /* webpackChunkName: "component---src-pages-imprint-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pharmaceutical-tsx": () => import("./../../../src/pages/pharmaceutical.tsx" /* webpackChunkName: "component---src-pages-pharmaceutical-tsx" */),
  "component---src-pages-use-cases-employee-onboarding-tsx": () => import("./../../../src/pages/use-cases/employee-onboarding.tsx" /* webpackChunkName: "component---src-pages-use-cases-employee-onboarding-tsx" */),
  "component---src-pages-use-cases-hotels-and-hospitality-tsx": () => import("./../../../src/pages/use-cases/hotels-and-hospitality.tsx" /* webpackChunkName: "component---src-pages-use-cases-hotels-and-hospitality-tsx" */)
}

